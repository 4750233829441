const SLOT = 'spotoken';

const ensureLocalStorage =
  (fn) =>
  (...args) =>
    typeof localStorage !== 'undefined' && fn(...args);

const TokenStorage = {
  getToken: ensureLocalStorage(() => JSON.parse(localStorage.getItem(SLOT))),
  setToken: ensureLocalStorage((token) =>
    localStorage.setItem(SLOT, JSON.stringify(token))
  ),
  clearToken: ensureLocalStorage(() => localStorage.removeItem(SLOT)),
};

export default TokenStorage;
