import { makeOperation } from '@urql/core';
import { authExchange } from '@urql/exchange-auth';
import { LogoutMutation } from 'queries';
import {
  Provider,
  cacheExchange,
  createClient,
  dedupExchange,
  fetchExchange,
} from 'urql';

import TokenStorage from './tokenStorage';

const getAuth = async ({ authState }) => {
  if (!authState) {
    const spotoken = TokenStorage.getToken();

    if (!spotoken) {
      return null;
    }

    const { accessToken, client, uid, expiry } = spotoken;

    if (accessToken && client && uid && expiry) {
      return { accessToken, client, uid, expiry };
    }

    return null;
  }

  return null;
};

const addAuthToOperation = ({ authState, operation }) => {
  if (!authState || !authState.accessToken) {
    return operation;
  }

  const fetchOptions =
    typeof operation.context.fetchOptions === 'function'
      ? operation.context.fetchOptions()
      : operation.context.fetchOptions || {};

  return makeOperation(operation.kind, operation, {
    ...operation.context,
    fetchOptions: {
      ...fetchOptions,
      headers: {
        ...fetchOptions.headers,
        'access-token': authState.accessToken,
        client: authState.client,
        uid: authState.uid,
      },
    },
  });
};

const didAuthError = ({ error }) => {
  return error.graphQLErrors.some(
    (e) => e.extensions?.code === 'AUTHENTICATION_ERROR'
  );
};

// Might prevent us from running login mutation
// https://formidable.com/open-source/urql/docs/advanced/authentication/
const willAuthError = ({ authState }) => {
  if (!authState || authState.expiry > new Date().getTime()) {
    return true;
  }

  return false;
};

const client = createClient({
  url: `${process.env.NEXT_PUBLIC_SPOTO_API_URL}/graphql`,
  exchanges: [
    dedupExchange,
    cacheExchange,
    authExchange({
      getAuth,
      addAuthToOperation,
      didAuthError,
      willAuthError,
    }),
    fetchExchange,
  ],
});

const logout = async () => {
  await client.mutation(LogoutMutation).toPromise();

  TokenStorage.clearToken();

  return true;
};

export { Provider, client, logout };
