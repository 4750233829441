import React from 'react';

import Head from 'next/head';

import 'core-js/actual/array/at';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isToday from 'dayjs/plugin/isToday';
import isTomorrow from 'dayjs/plugin/isTomorrow';

import { Provider, client } from 'lib/graphql';

import Notifications from 'components/Notifications';
import Drift from 'components/Script/Drift';
import GTM from 'components/Script/GTM';
import Heap from 'components/Script/Heap';

import '../styles/globals.css';

dayjs.extend(isToday);
dayjs.extend(isTomorrow);
dayjs.extend(advancedFormat);
dayjs.extend(customParseFormat);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

function MyApp({ Component, pageProps }) {
  const getLayout = Component.getLayout || ((page) => page);

  React.useEffect(() => {
    defineCoefficientCssVariableForInnerHeightOnMobileDevices();
    defineCoefficientCssVariableForVhOnMobileDevices();
  }, []);

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, user-scalable=no, viewport-fit=cover"
        />
      </Head>
      <GTM />
      <Heap />
      <Drift />

      <Notifications />

      <Provider value={client}>
        {getLayout(<Component {...pageProps} />)}
      </Provider>
    </>
  );
}

function defineCoefficientCssVariableForInnerHeightOnMobileDevices() {
  window.addEventListener('resize', () => {
    document.documentElement.style.setProperty(
      '--window-inner-height',
      `${window.innerHeight}px`
    );
  });
}

function defineCoefficientCssVariableForVhOnMobileDevices() {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);

  window.addEventListener('resize', () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  });
}

export default MyApp;
