import { gql } from '@urql/core';

export const LoginMutation = gql`
  mutation ($email: String!, $password: String!) {
    userLogin(email: $email, password: $password) {
      credentials {
        accessToken
        client
        expiry
        uid
      }
    }
  }
`;

export const LogoutMutation = gql`
  mutation {
    userLogout {
      authenticatable {
        email
      }
    }
  }
`;

export const RegisterMutation = gql`
  mutation (
    $email: String!
    $password: String!
    $passwordConfirmation: String!
  ) {
    userRegister(
      email: $email
      password: $password
      passwordConfirmation: $passwordConfirmation
    ) {
      credentials {
        accessToken
        client
        expiry
        uid
      }
    }
  }
`;

export const RequestPasswordResetMutation = gql`
  mutation ($email: String!, $redirectUrl: String!) {
    userSendPasswordResetWithToken(email: $email, redirectUrl: $redirectUrl) {
      message
    }
  }
`;

export const ResetPasswordMutation = gql`
  mutation (
    $password: String!
    $passwordConfirmation: String!
    $resetPasswordToken: String!
  ) {
    userUpdatePasswordWithToken(
      password: $password
      passwordConfirmation: $passwordConfirmation
      resetPasswordToken: $resetPasswordToken
    ) {
      authenticatable {
        email
      }
    }
  }
`;
