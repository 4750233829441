import { gql } from '@urql/core';

export const HostsQuery = gql`
  query ($tagId: ID, $personCount: Int, $availability: AvailabilityAttributes) {
    hosts: filterHosts(
      tagId: $tagId
      personCount: $personCount
      availability: $availability
    ) {
      id
      name
      displayAddress
      location {
        latitude
        longitude
      }
      coverImage {
        path
      }
    }
  }
`;

export const HostQuery = gql`
  query ($id: ID!) {
    host: node(id: $id) {
      ... on Host {
        id
        name
        displayAddress
        location {
          latitude
          longitude
        }
        coverImage {
          path
        }
        photos {
          path
        }
        openingHours {
          monday
          tuesday
          wednesday
          thursday
          friday
          saturday
          sunday
        }
        googleMapsSearchUrl
        googleMapsStaticmapUrl
        spots {
          id
          spotoShortName
          name
          description
          photo {
            path
          }
          price
          maxGuestsCapacity
          tag
        }
        tags {
          icon
          label
          position
        }
        highlights {
          icon
          label
          description
          position
        }
        amenities {
          icon
          label
          description
          position
        }
        features {
          icon
          label
          position
        }
      }
    }
  }
`;

export const TagsQuery = gql`
  query {
    tags {
      id
      label
      icon
      position
    }
  }
`;
