import { gql } from '@urql/core';

export const BookingsQuery = gql`
  query ($timeframe: Timeframe!) {
    bookings: myBookings(timeframe: $timeframe) {
      id
      date
      startAt
      endAt
      peopleCount
      status
      spot {
        name
        spotoShortName
        price
      }
      host {
        name
        coverImage {
          path
        }
      }
    }
  }
`;

export const BookingQuery = gql`
  query ($id: ID!) {
    booking: node(id: $id) {
      ... on Booking {
        id
        date
        startAt
        endAt
        peopleCount
        status
        spot {
          name
          spotoShortName
          wifi {
            name
            password
          }
          photo {
            path
          }
        }
        host {
          name
          displayAddress
          googleMapsSearchUrl
          coverImage {
            path
          }
        }
      }
    }
  }
`;

export const BookingReasonsQuery = gql`
  query {
    reasons {
      id
      label
      position
    }
  }
`;

export const BookingFeedbacksQuery = gql`
  query {
    feedbackItems {
      id
      label
      position
    }
  }
`;

export const BookingMutation = gql`
  mutation (
    $spotId: ID!
    $date: ISO8601Date!
    $startAt: Timeslot!
    $endAt: Timeslot!
    $peopleCount: Int!
  ) {
    createBooking(
      input: {
        spotId: $spotId
        date: $date
        startAt: $startAt
        endAt: $endAt
        peopleCount: $peopleCount
      }
    ) {
      booking {
        id
      }
    }
  }
`;

export const BookingRatingMutation = gql`
  mutation ($bookingId: ID!, $rating: Int!) {
    updateBookingFeedbackStarRating(
      input: { bookingId: $bookingId, feedbackStarRating: $rating }
    ) {
      booking {
        id
      }
    }
  }
`;

export const BookingFeedbackMutation = gql`
  mutation ($bookingId: ID!, $feedbackIds: [ID!]!) {
    updateBookingFeedbackItems(
      input: { bookingId: $bookingId, feedbackItemIds: $feedbackIds }
    ) {
      booking {
        id
      }
    }
  }
`;

export const BookingCustomFeedbackMutation = gql`
  mutation ($bookingId: ID!, $feedback: String!) {
    updateBookingFeedbackText(
      input: { bookingId: $bookingId, feedbackText: $feedback }
    ) {
      booking {
        id
      }
    }
  }
`;

export const BookingReasonMutation = gql`
  mutation ($bookingId: ID!, $reasonIds: [ID!]!) {
    updateBookingReasons(
      input: { bookingId: $bookingId, reasonIds: $reasonIds }
    ) {
      booking {
        id
      }
    }
  }
`;

export const CancelBookingMutation = gql`
  mutation ($bookingId: ID!) {
    cancelBooking(input: { bookingId: $bookingId }) {
      booking {
        id
      }
    }
  }
`;

export const SpotAvailabilityQuery = gql`
  query ($spotId: ID!, $date: ISO8601Date!) {
    availability(spotId: $spotId, date: $date) {
      timeslots {
        status
        startAt
        endAt
      }
    }
  }
`;
