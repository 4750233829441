import { gql } from '@urql/core';

export const UserProfileQuery = gql`
  query {
    me {
      email
      firstName
      lastName
      credits {
        balance
        expiringSoon {
          amount
          expiresAt
        }
        maxDaysAccumulatingCredits
        monthlyAllowance
      }
    }
  }
`;
